import classnames from "classnames"
import React from "react"
import Link from "/src/components/ui/base/Link"
import * as styles from "./index.module.scss"
import Typography from "/src/components/ui/base/typography"
import { useTranslation } from "react-i18next"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { PHOTO_TOURS_URL, STORY_URL } from "../../../../util/constants"

const StoryCard = ({ story }) => {
  const { i18n: l } = useTranslation()

  const storyLink = `/${PHOTO_TOURS_URL[l.language]}/${STORY_URL[l.language]}/${
    story.slug
  }`

  return (
    <Link className={classnames([styles.container])} to={storyLink}>
      <GatsbyImage
        className={classnames([styles.gatsbyImage])}
        alt={story.name}
        image={story?.photo?.localFile?.childImageSharp.gatsbyImageData}
      />
      <div className={classnames([styles.wrapper])}>
        <Typography variant="heading2">{story.name}</Typography>
        <Typography variant="heading3">
          Scattata da {story.photographer.firstName}
        </Typography>
      </div>
    </Link>
  )
}
StoryCard.propTypes = {
  story: PropTypes.any,
}

StoryCard.defaultProps = {
  story: null,
}

export default StoryCard
