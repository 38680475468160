import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Typography from "/src/components/ui/base/typography"
import { graphql } from "gatsby"
import Header from "../../components/ui/base/layout/header"
import Breadcrumbs from "../../components/ui/base/Breadcrumbs"
import Section from "../../components/ui/base/Section"
import classnames from "classnames"
import * as styles from "../tour/index.module.scss"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import { useTranslation } from "react-i18next"
import StoryCard from "../../components/ui/base/StoryCards"
import { PHOTO_TOURS_URL, STORY_URL } from "../../util/constants"

const StoriesPage = ({ data }) => {
  const { i18n: l } = useTranslation()

  const stories = data.allStrapiStory.nodes
  return (
    <>
      <GatsbySeo
        htmlAttributes={{ lang: l.language }}
        title={"Storie di viaggio"}
        description={
          "Le storie di viaggio raccontante attraverso le nostre foto"
        }
        canonical={`${STORY_URL[l.language]}/`}
        language={l.language}
      />
      <Header
        size={"compact"}
        videoURL={"viaggio-fotografico-aurora-boreale"}
        theme="dark"
      >
        <Container fluid="xl">
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="intro_title text-center">
                <Typography className="animated fadeInDown" variant="heading1">
                  Storie di viaggio
                </Typography>
                <Breadcrumbs
                  elements={[
                    {
                      name: "WeShoot",
                      path: "/",
                    },
                    {
                      name: "Viaggi Fotografici",
                      path: `/${PHOTO_TOURS_URL[l.language]}/`,
                    },
                    {
                      name: "Storie di viaggio",
                      path: `/${PHOTO_TOURS_URL[l.language]}/${
                        STORY_URL[l.language]
                      }/`,
                    },
                  ]}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Header>

      <Section variant={"white"}>
        <div className={classnames([styles.collectionContainer])}>
          {stories.map((story, i) => {
            return <StoryCard story={story} key={i} />
          })}
        </div>
      </Section>
    </>
  )
}

export const query = graphql`
  query StoriesQuery($locale: String!) {
    allStrapiStory(filter: { locale: { eq: $locale } }) {
      nodes {
        slug
        name
        tour {
          locale
        }
        photo {
          localFile {
            ...ImageFragment
          }
        }
        photographer {
          id
          firstName
          username
          bio
          instagram
          profilePicture {
            localFile {
              ...ImageFragment
            }
          }
        }
        id
        description
      }
    }
  }
`

export default StoriesPage
